import { walletStore } from '@/stores/wallet-store'
import { when } from 'mobx'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/pools' },
  {
    path: '/pools',
    name: 'IDOPools',
    component: () => import('../modules/ido/pages/ido-pools.vue')
  },
  {
    path: '/farm',
    name: 'Farm',
    component: () => import('../modules/farm/pages/farm.vue')
  },
  {
    path: '/stake',
    name: 'Staking',
    component: () => import('../modules/staking/pages/staking.vue')
  },
  {
    path: '/invest',
    name: 'BInvest',
    component: () => import('../modules/ido/pages/ido-pools.vue')
  },
  {
    path: '/pool/:poolid',
    name: 'IDOPoolDetail',
    component: () => import('../modules/ido/pages/ido-pool-detail.vue')
  },
  {
    path: '/allPools',
    name: 'AllPools',
    component: () => import('../modules/ido/pages/all-pools.vue')
  },
  {
    path: '/allAllocations',
    name: 'AllAllocations',
    component: () => import('../modules/ido/pages/all-allocations.vue')
  },
  {
    path: '/b-nft',
    name: 'NFTPools',
    component: () => import('../modules/nft/pages/pools.vue')
  },
  {
    path: '/b-nft/:name',
    name: 'NFTPoolDetail',
    component: () => import('../modules/nft/pages/pool-detail.vue')
  },
  {
    path: '/settingSolana',
    name: 'SettingSolanaWallet',
    component: () => import('../modules/ido/pages/setting-solana-wallet.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  await when(() => walletStore.loaded)
  next()
})

export default router
